/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import { CButton, CFooter, CModalFooter } from "@coreui/react";
import './ConverterList.css'
import AdminStore from "@store/AdminStore";
import { toJS } from "mobx";
import { CModal, CModalHeader, CModalTitle } from "@coreui/react";
import Spinner from "@components/Spinner";
import ReactDOM from "react-dom";
import moment from "moment";
import Select, { components } from 'react-select';
import { Helmet } from "react-helmet";
const $ = window.$;
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { toast } from "react-toastify";
import { BASE_API_URL_FILE } from "../../../api/config";
import ExcelPreview from "@elements/excelViewer/ExcelPreview";

var today = new Date();

const ConverterList = observer(() => {

    const tableRef = useRef()
    const tableName = "table1"

    const inputOneRef = useRef(null);
    const inputTwoRef = useRef(null);
    const [converterHistoryList, setConverterHistoryList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [viewExcel, setViewExcel] = useState("");
    const [paginationLength, setPaginationLength] = useState(25);
    const [employeeMap, setEmployeeMap] = useState({});

    const [convertedFile, setConvertedFile] = useState();
    const [employeeCodeMap, setEmployeeCodeMap] = useState({});
    const [csvData, setCsvData] = useState([]);
    const [fileNames, setFileNames] = useState({ input1: "", input2: "" });
    useEffect(() => {
        AdminStore.getConverterCSV();
    }, []);

    useEffect(() => {
        setConverterHistoryList(toJS(AdminStore?.data?.converterHistoryList))
    }, [AdminStore?.data?.converterHistoryList]);

    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: converterHistoryList,
                order: [], // Disable initial sorting
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'print',
                ],
                pageLength: paginationLength,
                lengthMenu: [25, 50, 100, 500, 1000],
                columns: [
                    {
                        title: "Id", data: "id", width: 30, "render": function (data, type, row, meta) {
                            return data || "-";
                        }
                    },
                    {
                        title: "Converted File", "render": function (data, type, row, meta) {
                            return row?.file_url?.split('/').pop() || "-";
                        }
                    },
                    {
                        title: "Last Updated By", width: 100, data: 'last_updated_by', "render": function (data, type, row, meta) {
                            return (row?.admin?.first_name + " " + row?.admin?.last_name) || "-"
                        }
                    },
                    {
                        title: "Last Update", width: 100, data: 'updated_at', "render": function (data, type, row, meta) {
                            return moment(data).format('hh:mm A') || "-";
                        }
                    },
                    {
                        title: "Action"
                    }
                ],
                columnDefs: [

                    {
                        targets: 4,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CButton
                                        onClick={() => {
                                            const link = document.createElement('a');
                                            link.href = BASE_API_URL_FILE + rowData?.file_url;
                                            link.setAttribute('download', BASE_API_URL_FILE + rowData?.file_url);
                                            link.setAttribute('target', '_blank');
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link);
                                        }}
                                        size="sm" className="w-100" variant='outline' color="success">
                                        Download <i className="fas fa-download"></i>
                                    </CButton>
                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found",
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1, 2])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter per page" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });


                },
            });
        // Extra step to do extra clean-up.
        return function () {
            $(`#${tableName}`).off('length.dt');
            table.destroy()
        }
    }, [converterHistoryList])



    const onOpenForm = () => {
        setFileNames({ input1: "", input2: "" })
        setIsOpen(true)
    }
    const onDismiss = () => {
        if (inputOneRef.current) {
            inputOneRef.current.value = '';
        }
        if (inputTwoRef.current) {
            inputTwoRef.current.value = '';
        }
        setIsOpen(false)
        setConvertedFile()
        setCsvData([])
        setEmployeeCodeMap({})
    }
    const onReset = () => {
        if (inputOneRef.current) {
            inputOneRef.current.value = '';
        }
        if (inputTwoRef.current) {
            inputTwoRef.current.value = '';
        }
        setConvertedFile()
        setCsvData([])
        setEmployeeCodeMap({})
        setFileNames({ input1: "", input2: "" })
    }
    // Function to handle the file upload
    const handleFileUpload = (event, fileIndex) => {
        const file = event.target.files[0];
        if (!file) {
            return
        }
        setFileNames({
            ...fileNames,
            [fileIndex]: file.name,
        });
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

            if (fileIndex === 1) {
                mapFirstFile(worksheet);
            } else if (fileIndex === 2) {
                mapSecondFile(worksheet);
            }
        };
        reader.readAsArrayBuffer(file);
    };

    // Function to map the first XLSX file data
    const mapFirstFile = (data) => {
        const map = data.reduce((acc, row) => {
            acc[row.Employee_Code] = row.Employee_Name;
            return acc;
        }, {});
        setEmployeeMap(map);
        const mapCode = data.reduce((acc, row) => {
            acc[row.Employee_Code] = row['ClockSeq_#'];
            return acc;
        }, {});
        setEmployeeCodeMap(mapCode);
    };

    // Function to map the second XLSX file data
    const mapSecondFile = (data) => {
        const mappedData = data?.filter(row => !row.EarnCode)?.map((row) => {
            // const employeeName = employeeMap[row.EECode] || '';
            const employeeCode = employeeCodeMap[row.EECode] || '';
            const inPunchTime = new Date(row.InPunchTime);
            const outPunchTime = new Date(row.OutPunchTime);

            const startDate = inPunchTime.toLocaleDateString();
            const startTime = inPunchTime.toLocaleTimeString('en-US', { hour12: false });
            const endTime = outPunchTime.toLocaleTimeString('en-US', { hour12: false });

            return {
                EmployeeCode: employeeCode,
                EmployeeName: '',//employeeName
                StartDate: startDate,
                StartTime: startTime,
                EndTime: endTime,
                TotalHours: row.EarnHours || 0,
                UnitCostCenter: row['Dist Unit Code Code'] || '',
                FacilityCode: '',//row['Home Facilities Desc'] || 
                Lunch: 0, // Adjust based on your data if lunch break needs to be calculated
                OnCallFlag: '', // Adjust if applicable
                CallBackFlag: '', // Adjust if applicable
                ChargeFlag: '', // Adjust if applicable
            };
        });

        setCsvData((prevData) => [...prevData, ...mappedData]);
    };

    // Function to download the CSV
    const convertCSV = () => {
        console.log(employeeCodeMap, csvData);

        if (!employeeCodeMap) {
            toast.error("Please upload EE Data file")
            return
        }
        if (!csvData || csvData?.length === 0) {
            toast.error("Please upload Time Detail file")
            return
        }
        const csvHeader = [
            'UnitCostCenter',
            'EmployeeCode',
            'EmployeeName',
            'StartDate',
            'StartTime',
            'EndTime',
            'Lunch',
            'TotalHours',
            'OnCallFlag',
            'CallBackFlag',
            'ChargeFlag',
            'FacilityCode',
        ];

        const csvArray = csvData?.map((row) => [
            row.UnitCostCenter,
            row.EmployeeCode,
            row.EmployeeName,
            row.StartDate,
            row.StartTime,
            row.EndTime,
            row.Lunch,
            row.TotalHours,
            row.OnCallFlag,
            row.CallBackFlag,
            row.ChargeFlag,
            row.FacilityCode,
        ]);

        const csvString = Papa?.unparse({
            fields: csvHeader,
            data: csvArray,
        });
        setConvertedFile(csvString)
    };

    const downloadCSV = () => {
        const blob = new Blob([convertedFile], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = "Paycom_to_bluesky_mapped_" + (today.getMonth() + 1) + '_' + today.getDate().toString() + '_' + today.getFullYear() + '_' + today.getHours() + "_"
            + today.getMinutes() + '.csv';
        link.click();
        const file = new File([convertedFile], "Paycom_to_bluesky_mapped_" + (today.getMonth() + 1) + '_' + today.getDate().toString() + '_' + today.getFullYear() + '_' + today.getHours() + "_"
            + today.getMinutes() + '.csv', { type: "csv" });
        const formData = new FormData();
        formData.append('file', file);
        AdminStore.saveConverterCSV(formData, navigationCallBackImport)
    }
    const navigationCallBackImport = () => {
        AdminStore.getConverterCSV();
        onDismiss()
        toast.success("Successfully uploaded")
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Converter List</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-user-friends"></i>
                                </span> Converter Records
                            </h3>
                            <div onClick={() => onOpenForm()} className='col-12 d-flex justify-content-lg-end pt-lg-0 pt-2'>
                                <h1 className="page-title signup-link fs-4" >
                                    <span className="page-title-icon linear text-white me-2">
                                        <i className="fas fa-plus"></i>
                                    </span>Convert File
                                </h1>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="row bg-white table-responsive" style={{ paddingTop: 20 }}>
                                <div className="table-wrapper">
                                    <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            <Spinner isLoading={AdminStore.loading} />
            <CModal size="lg" visible={isOpen} onClose={() => onDismiss()} backdrop="static">
                <CModalHeader onClose={() => onDismiss()}>
                    <CModalTitle>Converter</CModalTitle>
                </CModalHeader>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card border-secondary my-box-height">
                                <div className="card-header">Upload EE Data for BS</div>
                                <div className="card-body">
                                    <small className="text-muted text-center">Only xlsx files are accepted.</small>

                                    <div className="= overflow-auto">
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" ref={inputTwoRef} onChange={(e) => handleFileUpload(e, 1)} id="input1" accept=".xlsx" />
                                            <label className="custom-file-label" for="input1">{fileNames[1] || "Drag and drop xlsx file or click to upload"}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card border-secondary my-box-height">
                                <div className="card-header">Upload Time Detail for BS</div>
                                <div className="card-body">
                                    <small className="text-muted text-center">Only xlsx files are accepted.</small>

                                    <div className="overflow-auto">
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" ref={inputOneRef} onChange={(e) => handleFileUpload(e, 2)} id="input2" accept=".xlsx" />
                                            <label className="custom-file-label" for="input2">{fileNames[2] || "Drag and drop xlsx file or click to upload"}</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {convertedFile && <div> <label className="my-2 ps-2">Preview (Total {csvData?.length || "0"} entries)</label><div className="responsive-container">
                    <ExcelPreview
                        file={new Blob([convertedFile], { type: 'text/csv;charset=utf-8;' })}
                        width="100%" /* make it full width */
                        height="100%" /* make it full height */
                    />
                </div></div>}
                <CModalFooter className="justify-content-center">
                    <CButton color="secondary" className="text-white" onClick={() => onDismiss()}>
                        Close
                    </CButton>
                    <CButton color="secondary" className="text-white" onClick={() => onReset()}>
                        Reset
                    </CButton>
                    <CButton color="primary" onClick={convertCSV}>Convert <i className="fas fa-exchange-alt"></i></CButton>
                    <CButton color="primary" disabled={!convertedFile} onClick={downloadCSV}>Download <i className="fas fa-file-download"></i></CButton>
                </CModalFooter>
            </CModal>
            {/* </body> */}

            <CModal size="lg" backdrop="static" visible={viewExcel} onClose={null} >
                <CModalHeader onClose={null}>
                    <CModalTitle>Are You Sure To Export?</CModalTitle>
                </CModalHeader>
                <div>
                    <ExcelPreview file={viewExcel} />
                </div>
            </CModal>
        </React.Fragment>
    );
})
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? props?.selectProps?.isRequired ? "did-floating-label did-floating-label-top did-floating-labe-astrisk" : "did-floating-label did-floating-label-top" : props?.selectProps?.isRequired ? "did-floating-label did-floating-label did-floating-labe-astrisk" : "did-floating-label did-floating-label"} style={{ zIndex: 100 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};

export default ConverterList;
